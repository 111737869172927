.login-containerReset {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all 0.5s ease-out 0s;
  width: 22%;
  height: 23rem;
  background: #ffffff;
  border: 1px solid #efefec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  position: fixed;
  top: 24%;
  left: 15%;
}

.loginPage {
  position: relative;
  background-image: url("../../../../Images/loginBackground.png");
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  height: 100vh;
}

.reset {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 30px;
  color: green;
  padding-top: 18px;
}

.loginHead {
  margin-top: 62px;
  font-weight: 400;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 129px;
}

.resetBtn {
  margin-bottom: 0rem;
  margin-top: 1.5rem;
  width: 40%;
  border-radius: 0.3rem;
  border: none;
  background-color: green;
  height: 2.3rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.imgHead {
  margin-top: -51px;
  flex-wrap: wrap;
  align-items: center;
}

.InputReset {
  margin-bottom: 2rem;
  border-radius: 16px;
  border: 0.1px solid;
  padding-left: 1.5rem !important;
}

.forgetReset {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #1b8d6d;
  margin-left: 37px;
  margin-bottom: -1rem;
  display: block;
  margin-top: -17px;
  cursor: pointer;
}

.reset-container {
  position: relative;
  width: 17rem;
  height: 2rem;
}

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width:94%;
  height: 50%;
  border: 0.2px solid #e1e5ee;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  color: rgb(66, 64, 64);
  outline: none;
  padding: 1.25rem;
  background: none;
}

form__input:hover {
  border-color: gray;
}

.form__input:focus {
  border-color: gray;
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: gray;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: white;

}

.form__input:focus~.form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus)~.form__label {
  top: -0.5rem;
  font-size: 0.8rem;
  left: 0.8rem;
}

.Reset-Password-button {
  width: 70px;
  height: 40px;
  float: right;
  background-color: var(--primary);
  margin: auto;
  color: white;

}

.reset-back-to-login {
  margin-right: 20%;
  margin-top: 15%;
  left: 20%;
}

.asterisk {
  font-size: 7px;
  color: #cc5452;
}

@media only screen and (max-width: 767px) {
  .login-containerReset {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    width: 80%;
    height: 22rem;
    background: #ffffff;
    border: 1px solid #fdfdf7;
    box-sizing: border-box;
    border-radius: 0.8rem;
    position: relative;
    top: 28%;
    align-items: center;
    flex-wrap: wrap;
    display: block;
    left: 10.5%;
  }

  .form__input {
    width:93%;
  }
}