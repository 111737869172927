.Company_holiday{
    width: auto;
    height: auto;
    padding-top: 23px ;
    padding-bottom: 23px;
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}


.Notee{
    font-family: Jost;
    font-size: 14px;
color: #000000;
}

.required label::after{
    content: "*";
    color: red;
    font-weight: bold;
}

.cmpny{ 
    font-family: Jost;
    font-size: 15px;
    color: #060606;
}

.holiday{
 
    font-family: Jost;
    font-size: 15px;
  color: #060606;
}

.location{
    font-family: Jost;
    font-size: 15px;
   color: #060606;
}

.select2{
  
    width: 15rem;
    height: 35px;
    margin: 4px 32px 17px 0;
    padding: 9px 12.5px 7px 15px;
    border-radius: 18px;
    background-color: #f2f5fa;
}
.FILE{
    width: 15rem;
    height: 35px;
    padding: 5px 157px 5px 14px;
    border-radius: 18px;
    background-color: #f2f5fa;
}



.btn1{
  
    float: right;
    width: 95px;
    height: 36px;
    padding: 8px 23px 6px;
    border-radius: 18px;
    background-color: #03356d;
   
    
}

.eyeee{

width: 16px;
height: 16px;
}
