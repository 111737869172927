.backImg {
  /* position: relative; */
  background-image: url("../../../../Images/loginBackground.png");
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.createContainer {
  /* backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all 0.5s ease-out 0s;
  width: 30%;
  height: auto;
  border: 1px solid #efefec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  background: #ffffff;
  position: fixed;
  top: 24%;
  left: 15%; */
  width: 25%;
  max-width: 30rem;
  min-width: 24rem;
  margin: 0 0 0 8rem;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-container {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
}

.input-container input {
  height: 4rem;
  gap: 1rem;
  font-size: x-large;
  font-weight: bold;
}

.otp-group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* width: 100%; */
  /* max-width: 360px; */
  /* column-gap: 10px; */
}

/* .submitBtnn {
  margin-top: -3rem;
} */

/* .closeBtnn {
  margin-left: 5.2rem;
  margin-top: -3rem;
} */
.verify-otp {
  background-color: #3ac600;
  border: none;
  font-weight: 500;

  /* border-radius: 12px; */
  /* width: 6.5rem;
  height: 2.5rem; */
  /* margin-left: 1.6rem; */
}

.icon {
  position: relative;
  left: -18rem;
}
.eyeIcon {
  padding: 10px;
  background: rgb(56, 65, 162);
  color: wheat;
  min-width: 50px;
  text-align: center;
}

/* .inputBox {
  margin-top: 5rem;
}
.inputText {
  width: 15.5rem;
} */

.pwdBox {
  /* margin-top: 1rem;
  margin-left: 2rem;
  padding-top: 0.5rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
}

/* class for password input textfields */
/* .txtBox { 
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-left: 2rem;
} */

/* .icon2 {
  position: relative;
  top: -1.5rem;
  left: 0.5rem;
} */

.otp-input {
  width: 2rem;
  height: 2.3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.verify-button {
  /* margin-left: 12rem; */
  background: #1b8d6d;
}

.pass-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pass-form__button-row {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  gap: 1rem;
}

/* .pass-box {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  margin-left: 1.6rem;
} */

/* .reset {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 30px;
  color: green;
  padding-top: 18px;
} */
.submitBtn {
  /* margin-bottom: 0rem;
  margin-top: 0.5rem;
  margin-left: 6rem;
  width: 29%;
  border-radius: 0.3rem;
  border: none;
  height: 2rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center; */
  background-color: #3ac600;
}

.closeBtn {
  /* margin-bottom: 0rem;
  margin-top: 0.5rem;
  margin-left: 6rem; */
  /* width: 29%;
  border-radius: 0.3rem;
  border: none;
  height: 2rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center; */
  /* color: #303030; */
  /* border-color: #3ac600; */
  /* border-width: 4px; */
  /* border-radius: 8px; */
  /* font-weight: 500; */
  background: #1b8d6d;
}

.close-button {
  /* margin-top: 2rem;
  margin-left: 20rem; */
}

/* @media (width > 1440px) {
  .createContainer {
    width: 30%;
    margin-left: 8rem;
    padding: 4rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
} */

@media (475px < width < 900px) {
  .backImg {
    justify-content: center;
  }

  .createContainer {
    width: 50%;
    max-width: auto;
    min-width: auto;
    margin-left: 0px;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
  }
}

@media only screen and (max-width: 475px) {
  .backImg {
    justify-content: center;
  }

  .createContainer {
    width: 90%;
    max-width: auto;
    min-width: auto;
    margin-left: 0px;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    /* backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    width: 80%;
    height: 24rem;
    background: #ffffff;
    border: 1px solid #efefec;
    box-sizing: border-box;
    border-radius: 0.8rem;
    position: relative;
    top: 24.5%;
    align-items: center;
    flex-wrap: wrap;
    display: block;
    left: 10.5%; */
  }
}
