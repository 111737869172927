@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* these are the global css colors */

body {
  background-color: lightgray;
  font-family: "Poppins", sans-serif;
  font-size: smaller;
  --seconday: #f8fafb;
  --primary: #2f4f4f;
  --black: #000000;
  --white: #ffffff;
  --gray: gray;
  --Navy_blue: #03356d;
  --light-gray: lightgray;
  --Black-2: #060606;
  --red: red;
  --white-2: #f2f5fa;
  --choose_file_white: #e1dada;
  --line_white: #d2d0d0;
  --button: #03356d;
  --blue: #03356d;
  /* background-image: url(./Images/cool-background.png); */
  background-color: #f3fef3;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #232323;
}

/* use this for any card header */
.all-card-headers {
  font-size: 14px !important;
}

.c-pointer{
  cursor: pointer;
}

/* use this for any input labels */
.input-labels {
  font-family: Jost;
  font-size: 15px;
  color: var(--black);
  font-weight: normal !important;
  line-height: 13px !important;
  /* background-color: var(--seconday); */
}

/* use this for any bold card header */
.bold-card-header {
  font-size: 20px;
  font-weight: 500;
}

/* use this for any table heading */
.table-heading-top {
  font-size: 15px;
  font-family: Jost;
  font-weight: 500;
  color: var(--blue) !important;
}

/* use this for any table body texts */
.table-body-texts {
  font-size: 14px;
  font-family: Jost;
  /* font weight will be normal */
}

/* use this for any model header */
.model-top-header {
  font-size: 25px;
  font-weight: 500;
}

/* common button */
.common-btn {
  padding: 8px 23px 6px;
  border-radius: 18px;
  background-color: var(--blue);
  display: block;
  color: var(--white) !important;
}

.center-msg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdw-editor-main {
  height :fit-content !important;
  overflow: auto !important;
  box-sizing: border-box !important;
}
