.testimonials-charts-head {
  margin-left: 2.5rem;
}

.testimonials-charts {
  margin-left: 2.5rem;
  margin-top: 2rem;
}

.Select-Company-testimonials {
  width: 100%;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
}
.Select-Company-ET {
  width: 100%;
  height: 2.3rem;
  font-family: Jost;
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}
.upload-image-testimonials {
  width: 100%;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 5px 5px 5px 14px;
  border-radius: 18px;
  background-color: var(--seconday);
}

.input-field-testimonials {
  width: 14rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
  border: none;
}

.text-field-testimonials {
  width: 20rem;
  height: 5rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  /* border-radius: 35px; */
  background-color: var(--seconday);
  outline: none;
  border: none;
}

.testimonials-charts-text {
  margin-top: 4rem;
  /* margin-left: 1rem; */
}

#myfile{
  width: 100%;
}