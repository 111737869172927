.Rectangle-company-social-link{
  width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
  padding-top: 23px ;
  padding-bottom: 23px;
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color:var( --white);
  }
  .company-social-button{
    padding: 8px 23px 6px 24px;
    border-radius: 18px;
    background-color:var(--button);
    
    float: right;
    margin-left:80%;
  }

  .company-social-link-input{

    width: 15rem;
    height: 35px;
    margin: 4px 32px 17px 0;
    padding: 9px 12.5px 7px 15px;
    border-radius: 18px;
    background-color: #f2f5fa;
  }
  .errors{
    color: red;
    font-size:12px;
  }
  
  @media only screen and (max-width: 583px) {
    .company-social-button{
      margin: auto;
      margin-top:10px;
    }
   }