.top-nav-toggle{
  margin-top: 1rem !important;
  border-radius: 15px 15px 15px 15px;
}
.about-company-text{
    font-size: 16px !important;
    color: #000 !important;
}
.toggle-navbar-search{
    border-radius: 15px 15px 15px 15px !important;
    outline: none;
    padding: 5px 5px;
    border: none;
    background-color: #f2f5fa;
}
.green-back{
    padding: 1rem 1rem 0rem 1rem;
    height: 7rem;
}
.list-containing-input{
    display: flex;
    align-items: center;
}


.navbar__right-elements {
    display: flex;
    /* width: 8rem;
    gap: 1.5rem; */
    /* flex-direction: row; */
    align-items: center;
    justify-content: flex-end;
    margin-right: 5px;
  }

  img.img-circle.elevation-2.avatar {
    width: 27px;
  }

  .details__title {
    font-size: small;
    font-weight: bolder;
    /* color: white; */
  }
  
  .details__id {
    font-size: small;
    color: grey;
  }

  .user-profile {
    display: flex;
    flex-direction: column;
    align-content: center;
    /* align-items: center; */
    gap: 3px;
    padding: 1rem 1rem 0rem 1rem;
    /* background-color: aquamarine; */
  }