.footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 50px;
  background-color: white;
  text-align: center;
  padding: 0 15px;
  z-index: 10;
}

.footer-content {
  margin-top: 15px;
}

.footer-left-para {
  text-align: left;
  color: #4d4d4d;
}

.footer-right-para {
  text-align: right;
  color: #4d4d4d;
}

.anchor-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  background-color: black;
  display: inline-block;
  margin-left: 5%;
  margin-right: 5%;
}

.footer-anchor {
  text-decoration: none;
  color: #1b8d6d;
  font-size: small;
}
.TopBottomSpace{
  margin-bottom: 100px;
  margin-top: 93px;
}
@media only screen and (max-width: 1000px) {
  .footer {
    height: auto;
  }
  .footer-left-para {
    text-align: center;
  }
  .footer-right-para {
    text-align: center;
  }
}
@media only screen and(max-width: 767px) {
  .footer{
    position: relative;
  }
  .TopBottomSpace{
    margin-bottom: 20px;
    margin-top:20px;
  }
}
.card + button {
  margin: 3rem auto 0;
  display: flex;
}

textarea.input-field-form2.input-labels{
  line-height: 22px !important;
}