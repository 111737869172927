@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

.Append {
  margin-top: 62px;
  border: 0.1px solid var(--light-gray);
  height: 31px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-left: 0px;
}

.btn{
  color: var(--gray);
}


.input-group > .form-control {
  width: 200px;
  height: 32px;
  margin: 20px 0;
  padding: 7px 12.8px 5px 17px;
  border-radius: 18px;
  border-right: 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--white);
}

.btn2 {
  float: right;
  width: 156px;
  margin-right: 7%;
  margin-bottom: 4%;
  height: 36px;
  padding: 8px 29px 6px 19.2px;
  border-radius: 18px;
  background-color: var(--Navy_blue);
}

