.activeLink {
  background-color: #03356d;
  /* background-color: rgb(0, 87, 186); */
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.122);
}

.activeLink:hover {
  background-color: #3472e6 !important;
  box-shadow: 5px 2px 5px 2px rgba(0, 0, 0, 0.122) !important;
}

.activeLink:focus {
  background-color: #2B5FC2 !important;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.122) !important;
}

.admin-sidebar,
.main-sidebar {
  height: 100vh !important;
  position: fixed !important;
  /* background: hsl(120, 79%, 30%); */
  background: #6cd52b;
  background: linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
  background: -webkit-linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
  background: -moz-linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
}

.main-sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar {
  padding-left: 0px;
  padding-right: 0px;
  /* overflow-y: auto; */
  height: 80vh;
}

.sidebar::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.sidebar::-webkit-scrollbar-button {
  height: 50px;
}

.sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #1b8d6d;
  /* background-color: #426ec0; */
}

.nav-item p {
  color: var(--violet) !important;
}

.nav-item i {
  margin-right: 5px;
}

.hr-logo-link {
  background: var(--white) !important;
  height: 5rem;
}

.hr-logo-link img {
  width: 100%;
  height: 100%;
}

.dashboard-column a {
  border-radius: 0 !important;
  background: #7ebb5a !important;
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
}

.dashboard-column p {
  border-radius: 0 10% !important;
}

.sidebar-icons-color {
  color: var(--white) !important;
  font-size: 1rem;
}

.sub-maga-links {
  margin-left: 3rem;
}

.center-align {
  display: flex;
  align-items: center;
}

.admin-sidebar .sidebar {
  padding: 0px !important;
}

.admin-sidebar .brand-link.seed-link {
  border-bottom: 0px !important;
}

.navbar-white {
  background-color: rgb(83 175 32 / 20%) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #418c14 !important;
}

.content {
  padding: 15px;
}

.content-wrapper {
  background: transparent;
}

.table-responsive {
  padding: 0px !important;
  margin: 0px !important;
}

.form-control {
  padding: 3px;
}

.editor-card {
  width: 100% !important;
}

.wrapper {
  width: 99%;
}

.input-labels {
  font-size: 16px;
  font-weight: bold !important;
}

.labels {
  vertical-align: middle;
  padding-left: 5px;
}

tfoot {
  padding-left: 20px;
  width: 100%;
  float: left;
}

/* 
navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item>.nav-link.active {
    background: #2846d5;
    background: #0bbea6;
    margin-right: 0;
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0;
}
 */

@media screen and (max-width: 400px) {
  .wrapper {
    width: 98%;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 10px 7px;
  }

  .dash-card-title {
    font-size: 3rem !important;
  }
}

.nav-sidebar a {
  display: flex !important;
  align-items: flex-start !important;
  width: 100%;
  justify-content: flex-start;
}

.nav-item a {
  display: flex !important;
  align-items: center !important;
  /* align-items: flex-start !important; */
  width: 100%;
  justify-content: flex-start;
}

.brand-link.seed-link {
  background: transparent;
}
.nav-sidebar .nav-item > .nav-link {
  position: relative;
  color: white !important;
}
