.notification-head {
  margin-left: 2.5rem;
}

.notification {
  margin: 2rem 2rem 0 2rem;
}

.Select-Company-notification {
  width: 20.5rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
}
.Select-Company {
  width: 20.5rem;
  height: 2.3rem;
  font-family: Jost;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}
.upload-image-notification {
  width: 20.5rem;
  height: 2.3rem;
  margin: 3px 198px 18px 32px;
  padding: 5px 157px 5px 14px;
  border-radius: 18px;
  background-color: var(--seconday);
}
