.upload-document-format {
  width: 1200px;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  border: 1px solid;
  border-color: #d3d3d3;
}

.italic-subscript {
  font-size: small;
  font-style: italic;
}

.upload-document-heading {
  margin: 25px 50px 0 50px;
}

.upload-document-hr {
  margin: 0;
}

.upload-bar {
  border: 1px solid;
  border-color: #d3d3d3;
  border-radius: 5px;
  margin: 15px 0 0 0;
}

.upload-icon {
  color: #4d4d4d;
  vertical-align: middle;
}

.upload-document-para {
  padding: 0;
  margin: 0;
}

.upload-progress-icons {
  display: inline-block;
}

.upload-progress-icons i {
  margin-left: 20px;
}

.edit-icon {
  font-size: 1.2rem;
  color: #4d4d4d;
}

.delete-icon {
  font-size: 1.2rem;
  color: #4d4d4d;
}

.upload-resume {
  display: inline-block;
}

.upload-document-buttons {
  margin: 5% 2.5% 5% 2.5%;
}

.back-button {
  float: left;
}

.save-next-button {
  float: right;
}

.upload-bar {
  border: 1px solid;
  border-color: #d3d3d3;
  display: flex;
  border-radius: 5px;
  margin: 15px 0 0 0;
  flex-flow: wrap;
  align-items: center;
  padding: 15px;
}

.upload-resume {
  display: inline-block;
  width: calc(100% - 80px);
}

.upload-resume p {
  padding-left: 15px;
  vertical-align: middle;
  width: 86%;
}

.header-color {
  background-color: #1b8d6d;
}

nav.header-color {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-brand {
  margin-left: 15px;
}

.header-icon {
  color: white;
  margin-left: 5px;
  margin-top: 7px;
}

.breadcrumb {
  padding: 0;
  background: #fff;
  overflow: hidden;
  font-size: 12px;
  margin-bottom: -15px;
  margin-top: 5px;
  display: block;
}

.breadcrumb li {
  float: left;
}

.breadcrumb li a {
  color: white;
  text-decoration: none;
  padding: 10px 0 10px 65px;
  background: #1b8d6d;
  background: hsla(163, 68%, 40%, 0.647);
  position: relative;
  display: block;
  float: left;
}

.breadcrumb li a::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid #1b8d6d;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb li a::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumb li:first-child a {
  padding-left: 10px;
}

.breadcrumb li:nth-child(2) a {
  background: hsl(142deg 43% 55%);
}

.breadcrumb li:nth-child(2) a:after {
  border-left-color: hsl(142deg 43% 55%);
}

.breadcrumb li:last-child a {
  background: rgb(212, 200, 184) !important;
  color: black;
  pointer-events: none;
  cursor: default;
}

.breadcrumb li:last-child a::after {
  border-left-color: rgb(212, 200, 184);
}

.breadcrumb li a:hover {
  background: hsla(34, 85%, 25%, 1);
}

.breadcrumb li a:hover:after {
  border-left-color: hsla(34, 85%, 25%, 1) !important;
}

@media screen and (max-width: 767px) {
  .upload-document-format {
    width: 100% !important;
  }

  nav.header-color {
    position: relative;
  }
}

@media screen and (max-width: 400px) {
  .upload-document-heading {
    margin: 30px 15px !important;
  }

  .page-format {
    margin-top: 10px !important;
  }

  .row-format {
    padding: 0px !important;
  }

  .upload-document-format .col {
    margin-bottom: 15px;
    flex: 100%;
  }
}

nav.navbar.header-color img {
  width: auto;
  background: #fff;
  height: 30px;
  padding: 1px;
}

.navbar__right-elements {
  display: flex;
  width: 8rem;
  gap: 1.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.avatar {
  background-color: transparent;
  cursor: pointer;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-content: center;
  /* align-items: center; */
  gap: 3px;
  padding: 1rem 1rem 0rem 1rem;
  /* background-color: aquamarine; */
}

.details__title {
  font-size: small;
  font-weight: bolder;
  /* color: white; */
}

.details__id {
  font-size: small;
  color: grey;
}

