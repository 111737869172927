.editor-card{
    width: 100%;
    margin: 0 auto;
    margin-top: 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form-group{
    margin-left: 0 !important;
}
.makeStyles-card-5 {
    height: 500px;
    overflow-y: scroll;
}
.makeStyles-paper-1{
    background: #fff;
}
.form-group.d-flex.flex-column {
    width: 48% !important;
  }