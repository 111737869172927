.assign-process-align {
  position: relative;
  width: calc(100% - 230px);
  float: right;
  bottom: 75px;
  margin-top: 5%;
}

.assign-process-Rectangle-48 {
  margin: auto;
  width: 90%;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.assign-process-col {
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 5% 1% 5% 1%;
  padding-top: 2%;
}

.assign-process-heading {
  font-family: Jost;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.assign-process-email {
  border-radius: 18px;
  background-color: #e8ebf1;
  padding: 5px 10px 5px 10px;
}

.assign-process-label {
  font-family: Jost;
  font-size: 15px;
  color: #03356d;
}

.assign-process-checkbox-div {
  margin: 0;
  width: 100%;
}

.assign-process-checkbox {
  margin-top: 5px;
  float: right;
}

.assign-process-select {
  width: 50%;
  height: 30px;
  padding: 6px 10px 6.7px 9.5px;
  border-radius: 18px;
  background-color: #e8ebf1;
  margin-bottom: 10px;
}

.assign-save-button {
  border-radius: 18px;
  background-color: #74d02a;
  color: white;
  margin-right: 5px;
}

.assign-delete-button {
  border-radius: 18px;
  background-color: #03356d;
  color: white;
  margin-left: 5px;
}
