.Rectangle-My-company-logo {
  width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
  padding-top: 23px ;
  padding-bottom: 23px;
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color:var( --white);
  }
  

.Upload-Image{
 
  font-family: Jost;
  font-size: 15px;
color: #060606;
}





.Notee{
  font-family: Jost;
  font-size: 14px;
color: #000000;
}

.required label::after{
  content: "*";
  color: red;
  font-weight: bold;
}

.cmpny-logo{ 
  font-family: Jost;
  font-size: 15px;
  color: #060606;
}
.company-logo-button{
  padding: 8px 23px 6px 24px;
  border-radius: 18px;
  background-color:var(--button);
  
  float: right;
  margin-left:80%;
}

.upload-image-cmpny-logo{

  font-family: Jost;
  font-size: 15px;
color: #060606;
}

.location{
  font-family: Jost;
  font-size: 15px;
 color: #060606;
}


.custom-file-cmpny-logo{
  width: 15rem;
  height: 35px;
  padding: 5px 157px 5px 14px;
  border-radius: 18px;
  background-color: #f2f5fa;
}




.S_No_logo{
  margin-left: 1rem;
  font-family: Jost;
 
  color: #03356d;
}

.Name_logo{
margin-left: 15%;
font-family: Jost;

color: #03356d;
}

.data_found-cmpny-logo{
  width: auto;
  height: auto;
  padding: 15px 0 11px ;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color:var( --white);
  margin: 0px 22px 22px 27px;
}

@media only screen and (max-width: 583px) {
 .company-logo-button{
   margin: auto;
   margin-top:10px;
 }
}

