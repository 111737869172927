.pop-up-close-btn{
    position: absolute;
    top: 0%;
    left:90%;
    width: 3rem;
    border:none;
    outline: none;
    background: #0069d9;
    padding: 8px 8px;
    font-size: 1.5rem;
}