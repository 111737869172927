@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost&display=swap);

/* these are the global css colors */

body {
  background-color: lightgray;
  font-family: "Poppins", sans-serif;
  font-size: smaller;
  --seconday: #f8fafb;
  --primary: #2f4f4f;
  --black: #000000;
  --white: #ffffff;
  --gray: gray;
  --Navy_blue: #03356d;
  --light-gray: lightgray;
  --Black-2: #060606;
  --red: red;
  --white-2: #f2f5fa;
  --choose_file_white: #e1dada;
  --line_white: #d2d0d0;
  --button: #03356d;
  --blue: #03356d;
  /* background-image: url(./Images/cool-background.png); */
  background-color: #f3fef3;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #232323;
}

/* use this for any card header */
.all-card-headers {
  font-size: 14px !important;
}

.c-pointer{
  cursor: pointer;
}

/* use this for any input labels */
.input-labels {
  font-family: Jost;
  font-size: 15px;
  color: var(--black);
  font-weight: normal !important;
  line-height: 13px !important;
  /* background-color: var(--seconday); */
}

/* use this for any bold card header */
.bold-card-header {
  font-size: 20px;
  font-weight: 500;
}

/* use this for any table heading */
.table-heading-top {
  font-size: 15px;
  font-family: Jost;
  font-weight: 500;
  color: var(--blue) !important;
}

/* use this for any table body texts */
.table-body-texts {
  font-size: 14px;
  font-family: Jost;
  /* font weight will be normal */
}

/* use this for any model header */
.model-top-header {
  font-size: 25px;
  font-weight: 500;
}

/* common button */
.common-btn {
  padding: 8px 23px 6px;
  border-radius: 18px;
  background-color: var(--blue);
  display: block;
  color: var(--white) !important;
}

.center-msg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdw-editor-main {
  height :-webkit-fit-content !important;
  height :-moz-fit-content !important;
  height :fit-content !important;
  overflow: auto !important;
  box-sizing: border-box !important;
}

.activeLink {
  background-color: #03356d;
  /* background-color: rgb(0, 87, 186); */
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.122);
}

.activeLink:hover {
  background-color: #2B5FC2 !important;
  box-shadow: 5px 2px 5px 2px rgba(0, 0, 0, 0.122) !important;
}

.activeLink:focus {
  background-color: #2B5FC2 !important;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.122) !important;
}

.admin-sidebar,
.main-sidebar {
  height: 62rem !important;
  position: fixed !important;
  background: #6cd52b;
  background: linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
  background: -moz-linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
}
.sidebar {
  padding-left: 0px;
  padding-right: 0px;
  overflow-y: auto;
  height:40rem;
}

.nav-item p {
  color: var(--violet) !important;
}
.seed-link {
  /* background-color: var(--white); */
  height: 5rem;
}
.seed-link img {
  width: 100%;
  height: 100%;
}

.dashboard-column a {
  border-radius: 0 !important;
  background: #7ebb5a !important;
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
}
.dashboard-column p {
  border-radius: 0 10% !important;
}

.sidebar-icons-color {
  color: var(--white) !important;
  font-size: 1rem;
}
.sub-maga-links {
  /* margin-left: 3rem; */
}
.admin-sidebar .sidebar {
  padding: 0px !important;
}
.admin-sidebar .brand-link.seed-link {
  border-bottom: 0px !important;
}
.navbar-white {
  background-color: rgb(83 175 32 / 20%) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #418c14 !important;
}
.content {
  padding: 15px;
}
.content-wrapper {
  background: transparent;
}
.table-responsive {
  padding: 0px !important;
  margin: 0px !important;
}
.form-control {
  padding: 3px;
}
.editor-card {
  width: 100% !important;
}
.wrapper {
  width: 99%;
}
.input-labels {
  font-size: 16px;
  font-weight: bold !important;
}
.labels {
  vertical-align: middle;
  padding-left: 5px;
}
tfoot {
  padding-left: 20px;
  width: 100%;
  float: left;
}
@media screen and (max-width: 400px) {
  .wrapper {
    width: 98%;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 10px 7px;
  }
  .dash-card-title {
    font-size: 3rem !important;
  }
}
.nav-sidebar a {
  display: flex !important;
  /* align-items: flex-start !important; */
  align-items: center !important;
  width: 100%;
  justify-content: flex-start;
}

.active-link {
  font-weight: bold;
}


.card-body .form-group.d-flex.flex-column {
  width: 100% !important;
}
.top-nav-toggle {
  margin-top: 1rem !important;
  border-radius: 15px 15px 15px 15px;
}
.about-company-text {
  font-size: 16px !important;
  color: #000 !important;
}
.toggle-navbar-search {
  border-radius: 15px 15px 15px 15px !important;
  outline: none;
  padding: 5px 5px;
  border: none;
  background-color: #f2f5fa;
}
.green-back {
  padding: 1rem 1rem 0rem 1rem;
  height: 7rem;
}
.list-containing-input {
  display: flex;
  align-items: center;
}
/* .navbar{
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width:80%
} */
.login-container {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: all 0.5s ease-out 0s;
  width: 23%;
  height: 25rem;
  background: #ffffff;
  border: 1px solid #efefec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  position: absolute;
  top: 22.5%;
  left: 15%;
}

.loginPage {
  position: relative;
  background-image: url(/static/media/loginBackground.96e5f016.png);
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  height: 100vh;
}

.login {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 30px;
  color: green;
  padding-top: 18px;
}

.loginHead {
  margin-top: 62px;
  font-weight: 400;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 129px;
}

.loginBtn {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 44%;
  border-radius: 0.3rem;
  border: none;
  background-color: green;
  height: 2.3rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.imgHead {
  margin-top: -51px;
  flex-wrap: wrap;
  align-items: center;
}

.InputField {
  display: block;
  width: 85%;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 10px;
  border: 0.1px solid;
  padding-left: 1.5rem !important;
}

.forget {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #1b8d6d;
  margin-left: 24px;
  margin-bottom: -1rem;
  display: block;
  margin-top: -17px;
  cursor: pointer;
}

.label4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  color: #ffffff;
}

.label5 {
  top: calc(50% - 15px / 2 + 336px);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  color: #ffffff;
}

.login-footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}



@media only screen and (max-width: 767px) {
  .login-container {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    width: 80%;
    height: 24rem;
    background: #ffffff;
    border: 1px solid #efefec;
    box-sizing: border-box;
    border-radius: 0.8rem;
    position: relative;
    top: 24.5%;
    align-items: center;
    flex-wrap: wrap;
    display: block;
    left: 10.5%;
  }
}
.loader {
  /* overflow: hidden; */
  max-width: 100% !important;
  max-height: 100% !important;
}

.spinner {
  color:  #acacac7a;
  /* height: 100vh;
  width: 100vw;  */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.logo {
  width: 2.5rem;
  position: absolute;
  top: 49.5%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.login-containerReset {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: all 0.5s ease-out 0s;
  width: 22%;
  height: 23rem;
  background: #ffffff;
  border: 1px solid #efefec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  position: fixed;
  top: 24%;
  left: 15%;
}

.loginPage {
  position: relative;
  background-image: url(/static/media/loginBackground.96e5f016.png);
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  height: 100vh;
}

.reset {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 30px;
  color: green;
  padding-top: 18px;
}

.loginHead {
  margin-top: 62px;
  font-weight: 400;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 129px;
}

.resetBtn {
  margin-bottom: 0rem;
  margin-top: 1.5rem;
  width: 40%;
  border-radius: 0.3rem;
  border: none;
  background-color: green;
  height: 2.3rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.imgHead {
  margin-top: -51px;
  flex-wrap: wrap;
  align-items: center;
}

.InputReset {
  margin-bottom: 2rem;
  border-radius: 16px;
  border: 0.1px solid;
  padding-left: 1.5rem !important;
}

.forgetReset {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #1b8d6d;
  margin-left: 37px;
  margin-bottom: -1rem;
  display: block;
  margin-top: -17px;
  cursor: pointer;
}

.reset-container {
  position: relative;
  width: 17rem;
  height: 2rem;
}

.form__input {
  position: absolute;
  top: 0;
  left: 0;
  width:94%;
  height: 50%;
  border: 0.2px solid #e1e5ee;
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  color: rgb(66, 64, 64);
  outline: none;
  padding: 1.25rem;
  background: none;
}

form__input:hover {
  border-color: gray;
}

.form__input:focus {
  border-color: gray;
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: gray;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: white;

}

.form__input:focus~.form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus)~.form__label {
  top: -0.5rem;
  font-size: 0.8rem;
  left: 0.8rem;
}

.Reset-Password-button {
  width: 70px;
  height: 40px;
  float: right;
  background-color: var(--primary);
  margin: auto;
  color: white;

}

.reset-back-to-login {
  margin-right: 20%;
  margin-top: 15%;
  left: 20%;
}

.asterisk {
  font-size: 7px;
  color: #cc5452;
}

@media only screen and (max-width: 767px) {
  .login-containerReset {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    width: 80%;
    height: 22rem;
    background: #ffffff;
    border: 1px solid #fdfdf7;
    box-sizing: border-box;
    border-radius: 0.8rem;
    position: relative;
    top: 28%;
    align-items: center;
    flex-wrap: wrap;
    display: block;
    left: 10.5%;
  }

  .form__input {
    width:93%;
  }
}
.Dashboard{
    position: relative;
}


.Image-27 {
    /* position: absolute  ;
    width: 1366px;
    z-index: 1;
    height: 772px; */
    margin: 0 0 27px;
  padding: 0 0 27px;
   object-fit: contain;
  }
 
 
  .Image-345{
      /* position: absolute;
      z-index: 2;
    width: 678px;
    height: 476px;
    margin: 88px 58px 81px 630px; */
    object-fit: contain;
  }



 .Image-2222 {
    /* position: absolute;
    margin-top: 310px;
    margin-left: 280px;
    z-index: 3;
    width: 693px;
    height: 610px;
    margin: 35px 435px 0 238px;
    padding: 175px 291px 149px 42px; */
    object-fit: contain;
  }

 
.dash-card-title{
      /* position: absolute;
      z-index: 4;
      font-family: Jost;
      margin-left: 280px;
      
      margin-top:250px; */
      margin-top:4rem;
      width:100%;
  float: left;
  font-size: 64px;
  font-weight: 500;
   
    line-height: 50px;
   
  color: #74d02a;
  }

  .employee{
      /* position: absolute;
      z-index: 4;
      margin-left: 280px;
      margin-top:310px; */
      margin-top:2rem;
      width:100%;
  float: left;
    font-family: Jost;
    font-size: 33px;
  
    
    color: #8046d2;
  }

  .card-text{
    /* position: absolute;
    z-index: 4;
    margin-left: 280px;
    margin-top:360px; */
  font-family: Jost;
  width:100%;
  float: left;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #3a3a3a;
  }
.pop-up-close-btn{
    position: absolute;
    top: 0%;
    left:90%;
    width: 3rem;
    border:none;
    outline: none;
    background: #0069d9;
    padding: 8px 8px;
    font-size: 1.5rem;
}
.editor-card{
    width: 100%;
    margin: 0 auto;
    margin-top: 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form-group{
    margin-left: 0 !important;
}
.makeStyles-card-5 {
    height: 500px;
    overflow-y: scroll;
}
.makeStyles-paper-1{
    background: #fff;
}
.form-group.d-flex.flex-column {
    width: 48% !important;
  }
.Rectangle-My-company-logo {
  width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
  padding-top: 23px ;
  padding-bottom: 23px;
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color:var( --white);
  }
  

.Upload-Image{
 
  font-family: Jost;
  font-size: 15px;
color: #060606;
}





.Notee{
  font-family: Jost;
  font-size: 14px;
color: #000000;
}

.required label::after{
  content: "*";
  color: red;
  font-weight: bold;
}

.cmpny-logo{ 
  font-family: Jost;
  font-size: 15px;
  color: #060606;
}
.company-logo-button{
  padding: 8px 23px 6px 24px;
  border-radius: 18px;
  background-color:var(--button);
  
  float: right;
  margin-left:80%;
}

.upload-image-cmpny-logo{

  font-family: Jost;
  font-size: 15px;
color: #060606;
}

.location{
  font-family: Jost;
  font-size: 15px;
 color: #060606;
}


.custom-file-cmpny-logo{
  width: 15rem;
  height: 35px;
  padding: 5px 157px 5px 14px;
  border-radius: 18px;
  background-color: #f2f5fa;
}




.S_No_logo{
  margin-left: 1rem;
  font-family: Jost;
 
  color: #03356d;
}

.Name_logo{
margin-left: 15%;
font-family: Jost;

color: #03356d;
}

.data_found-cmpny-logo{
  width: auto;
  height: auto;
  padding: 15px 0 11px ;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color:var( --white);
  margin: 0px 22px 22px 27px;
}

@media only screen and (max-width: 583px) {
 .company-logo-button{
   margin: auto;
   margin-top:10px;
 }
}


.Rectangle-company-social-link{
  width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
  padding-top: 23px ;
  padding-bottom: 23px;
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color:var( --white);
  }
  .company-social-button{
    padding: 8px 23px 6px 24px;
    border-radius: 18px;
    background-color:var(--button);
    
    float: right;
    margin-left:80%;
  }

  .company-social-link-input{

    width: 15rem;
    height: 35px;
    margin: 4px 32px 17px 0;
    padding: 9px 12.5px 7px 15px;
    border-radius: 18px;
    background-color: #f2f5fa;
  }
  .errors{
    color: red;
    font-size:12px;
  }
  
  @media only screen and (max-width: 583px) {
    .company-social-button{
      margin: auto;
      margin-top:10px;
    }
   }
.table-responsive {
  width: auto;
  height: auto;

  margin: 0px 22px 33px 27px;
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--white);
}
.table-responsive.full-table.col-md-12 {
  margin: 0;
  width: 100%;
}
.MuiTypography-root {
  margin: 0;
  width: 100%;
}
.Note {
  font-family: Jost;
  font-size: 14px;
  line-height: 1.43;
  color: var(--black);
}

.required {
  height: 22px;
  margin-top: 21px;
  color: var(--Black-2);
}
.required p::after {
  content: "*";
  color: var(--red);
  font-weight: bold;
}

.select2 {
  font-size: 13px;
  width: 15rem;
  height: 35px;
  margin: -10px 44px 26px 3px;
  padding: 5px 12.5px 7px 15px;
  border-radius: 18px;
  background-color: var(--white-2);
}

.selected {
  margin: 0 69.5px 0 0;
  font-family: Jost;
  font-size: 13px;
  color: var(--black);
}

.grp {
  display: inline;
}

.checks_image {
  display: inline;
  /* margin-left: 7%; */
}

.checks_video {
  display: inline;
}

.check {
  display: inline;
  margin-left: 15px;
  border-radius: 50px;
  background-color: var(--white-2);
}

.labels {
  margin-left: 30px;
  color: var(--Black-2);
}
.upload {
  color: var(--Black-2);
}

.upload p::after {
  content: "*";
  color: var(--red);
  font-weight: bold;
}

.upload-image p::after {
  content: "*";
  color: var(--red);
  font-weight: bold;
}
.upload-image {
  margin: 21px 66px 3px 3px;

  color: var(--Black-2);
}

.custom-file {
  height: 35px;
  margin: 3px 0px 0 0px;
  padding: 5px;
  border-radius: 18px;
  background-color: var(--white-2);
}
.custom-label-input {
  cursor: pointer;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 13px;
  color: var(--choose_file_white);
}

.submmit {
  display: inline;
}

.btn4 {
  width: 95px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--Navy_blue);
}

.Company_Gallery {
    width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
    padding:20px;
    border-radius: 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--white);
}


.Note{
    font-family: Jost;
    font-size: 14px;
   line-height: 1.43;
  color: var(--black);
}

.required{
    height: 22px;
    margin-top: 21px;
 
    color: var(--Black-2);
}
.required p::after{
    content: "*";
    color: var(--red);
    font-weight: bold;
}

.select2{
    font-size: 13px;
    width: 15rem;
    height: 35px;
    margin: -10px 44px 26px 3px;
    padding: 6px 12.5px 7px 15px;
    border-radius: 18px;
    background-color: var(--white);
}

.selected{
  
  margin: 0 69.5px 0 0;
  font-family: Jost;
  font-size: 13px;
  color: var(--black);
}



.grp{
    display: inline;
    
    
}
.checks_image{
    display: inline;
    /* margin-left: 7%; */
}

.checks_video{
    display: inline;
   
}
.check{
    display: inline;
    margin-left: 15px;
    border-radius: 50px;
    background-color: var(--white-2);

}

.labels{
    margin-left: 30px;
   color: var(--Black-2);
}
.upload{
 
  color: var(--Black-2);

}

.upload p::after{
    content: "*";
    color: var(--red);
    font-weight: bold;
}

.upload-image p::after{
    content: "*";
    color: var(--red);
    font-weight: bold;
}
.upload-image{
  
  margin: 21px 66px 3px 3px;

  color: var(--Black-2);
}

.custom-file{   
    
    
    height: 35px;
    margin: 3px 0px 0 0px;
    padding: 5px;
    border-radius: 18px;
    background-color: var(--white-2);
}

.custom-label-input{

    cursor: pointer;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: Jost;
    font-size: 13px;
    color: var(--choose_file_white);
}

.submmit{
    display: inline;
}

.btn4{
   
    width: 95px;
    height: 36px;
    border-radius: 18px;
    background-color: var(--Navy_blue);
}





.Company_holiday{
    width: auto;
    height: auto;
    padding-top: 23px ;
    padding-bottom: 23px;
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}


.Notee{
    font-family: Jost;
    font-size: 14px;
color: #000000;
}

.required label::after{
    content: "*";
    color: red;
    font-weight: bold;
}

.cmpny{ 
    font-family: Jost;
    font-size: 15px;
    color: #060606;
}

.holiday{
 
    font-family: Jost;
    font-size: 15px;
  color: #060606;
}

.location{
    font-family: Jost;
    font-size: 15px;
   color: #060606;
}

.select2{
  
    width: 15rem;
    height: 35px;
    margin: 4px 32px 17px 0;
    padding: 9px 12.5px 7px 15px;
    border-radius: 18px;
    background-color: #f2f5fa;
}
.FILE{
    width: 15rem;
    height: 35px;
    padding: 5px 157px 5px 14px;
    border-radius: 18px;
    background-color: #f2f5fa;
}



.btn1{
  
    float: right;
    width: 95px;
    height: 36px;
    padding: 8px 23px 6px;
    border-radius: 18px;
    background-color: #03356d;
   
    
}

.eyeee{

width: 16px;
height: 16px;
}

.Append {
  margin-top: 62px;
  border: 0.1px solid var(--light-gray);
  height: 31px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-left: 0px;
}

.btn{
  color: var(--gray);
}


.input-group > .form-control {
  width: 200px;
  height: 32px;
  margin: 20px 0;
  padding: 7px 12.8px 5px 17px;
  border-radius: 18px;
  border-right: 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--white);
}

.btn2 {
  float: right;
  width: 156px;
  margin-right: 7%;
  margin-bottom: 4%;
  height: 36px;
  padding: 8px 29px 6px 19.2px;
  border-radius: 18px;
  background-color: var(--Navy_blue);
}



.Append {
  margin: 20px;
  border: 0.1px solid var(--light-gray);
  height: 31px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-left: 0px;
}

.input-group > .form-control {
  width: 200px;
}

.btn{
  color: var(--white);
}
.btn6 {
  float: left;
  /* width: 181px; */
  height: 36px;
  padding: 8px 29px 6px 19.2px;
  border-radius: 18px;
  background-color: var(--Navy_blue);
}


 



















.assign-process-align {
  position: relative;
  width: calc(100% - 230px);
  float: right;
  bottom: 75px;
  margin-top: 5%;
}

.assign-process-Rectangle-48 {
  margin: auto;
  width: 90%;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.assign-process-col {
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 5% 1% 5% 1%;
  padding-top: 2%;
}

.assign-process-heading {
  font-family: Jost;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.assign-process-email {
  border-radius: 18px;
  background-color: #e8ebf1;
  padding: 5px 10px 5px 10px;
}

.assign-process-label {
  font-family: Jost;
  font-size: 15px;
  color: #03356d;
}

.assign-process-checkbox-div {
  margin: 0;
  width: 100%;
}

.assign-process-checkbox {
  margin-top: 5px;
  float: right;
}

.assign-process-select {
  width: 50%;
  height: 30px;
  padding: 6px 10px 6.7px 9.5px;
  border-radius: 18px;
  background-color: #e8ebf1;
  margin-bottom: 10px;
}

.assign-save-button {
  border-radius: 18px;
  background-color: #74d02a;
  color: white;
  margin-right: 5px;
}

.assign-delete-button {
  border-radius: 18px;
  background-color: #03356d;
  color: white;
  margin-left: 5px;
}

.testimonials-charts-head {
  margin-left: 2.5rem;
}

.testimonials-charts {
  margin-left: 2.5rem;
  margin-top: 2rem;
}

.Select-Company-testimonials {
  width: 100%;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
}
.Select-Company-ET {
  width: 100%;
  height: 2.3rem;
  font-family: Jost;
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}
.upload-image-testimonials {
  width: 100%;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 5px 5px 5px 14px;
  border-radius: 18px;
  background-color: var(--seconday);
}

.input-field-testimonials {
  width: 14rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
  border: none;
}

.text-field-testimonials {
  width: 20rem;
  height: 5rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  /* border-radius: 35px; */
  background-color: var(--seconday);
  outline: none;
  border: none;
}

.testimonials-charts-text {
  margin-top: 4rem;
  /* margin-left: 1rem; */
}

#myfile{
  width: 100%;
}
.create-user-margin {
  /* margin-left: 2.5rem; */
  margin-top: 2rem;
}

.Select-Company-CU {
  width: 20.5rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
  margin-bottom: 2.5rem;
}
.Select-CompanyCU {
  width: 20.5rem;
  height: 2.3rem;
  font-family: Jost;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}

.search-CU {
  margin-left: 0.9rem;
}

.input-field-CU {
  width: 12rem;
  height: 2.3rem;
  /* margin-bottom: 2.5rem; */
  border: none;
  border-radius: 18px;
  background-color: var(--seconday);
}

.Company-lable {
  width: 99px;
  height: 22px;
  margin: 11px 280px 5px 3px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
}

.create-user-lable {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.input-left-margin-CU {
  margin-left: 4rem;
}
.multi-select-form {
  width: 20.5rem;
  height: 4.3rem;
}
.mb1-3 {
  margin-bottom: 2rem !important;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  /* background: url("/bg.jpg") no-repeat; */
  background-color: green;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 142px;
  }
  .notfound .notfound-404 h1 {
    font-size: 112px;
  }
}

.leader-modal .form-group.d-flex.flex-column {
  width: 48% !important;
}
.address-margin {
  margin-left: 2.5rem;
  margin-top: 2rem;
}

.Select-Company-HA {
  width: 20.5rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
}
/* #error{
  color: red;
  font-size:12px;
  display: none;
} */
.Select-CompanyHA {
  width: 20.5rem;
  height: 2.3rem;
  font-family: Jost;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}

.input-field-HA {
  width: 23rem;
  height: 2.3rem;
  margin-bottom: 2.5rem;
  border: none;
  border-radius: 18px;
  background-color: var(--seconday);
}

.input-left-margin {
  margin-left: 4rem;
}

.Company-lable {
  width: 99px;
  height: 22px;
  margin: 11px 280px 5px 3px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
}

.address-lable {
  margin: 1.1rem 14rem 5rem 0.3rem;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.notification-head {
  margin-left: 2.5rem;
}

.notification {
  margin: 2rem 2rem 0 2rem;
}

.Select-Company-notification {
  width: 20.5rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
}
.Select-Company {
  width: 20.5rem;
  height: 2.3rem;
  font-family: Jost;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}
.upload-image-notification {
  width: 20.5rem;
  height: 2.3rem;
  margin: 3px 198px 18px 32px;
  padding: 5px 157px 5px 14px;
  border-radius: 18px;
  background-color: var(--seconday);
}

.top-nav-toggle{
  margin-top: 1rem !important;
  border-radius: 15px 15px 15px 15px;
}
.about-company-text{
    font-size: 16px !important;
    color: #000 !important;
}
.toggle-navbar-search{
    border-radius: 15px 15px 15px 15px !important;
    outline: none;
    padding: 5px 5px;
    border: none;
    background-color: #f2f5fa;
}
.green-back{
    padding: 1rem 1rem 0rem 1rem;
    height: 7rem;
}
.list-containing-input{
    display: flex;
    align-items: center;
}


.navbar__right-elements {
    display: flex;
    /* width: 8rem;
    gap: 1.5rem; */
    /* flex-direction: row; */
    align-items: center;
    justify-content: flex-end;
    margin-right: 5px;
  }

  img.img-circle.elevation-2.avatar {
    width: 27px;
  }

  .details__title {
    font-size: small;
    font-weight: bolder;
    /* color: white; */
  }
  
  .details__id {
    font-size: small;
    color: grey;
  }

  .user-profile {
    display: flex;
    flex-direction: column;
    align-content: center;
    /* align-items: center; */
    grid-gap: 3px;
    gap: 3px;
    padding: 1rem 1rem 0rem 1rem;
    /* background-color: aquamarine; */
  }
.activeLink {
  background-color: #03356d;
  /* background-color: rgb(0, 87, 186); */
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.122);
}

.activeLink:hover {
  background-color: #3472e6 !important;
  box-shadow: 5px 2px 5px 2px rgba(0, 0, 0, 0.122) !important;
}

.activeLink:focus {
  background-color: #2B5FC2 !important;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.122) !important;
}

.admin-sidebar,
.main-sidebar {
  height: 100vh !important;
  position: fixed !important;
  /* background: hsl(120, 79%, 30%); */
  background: #6cd52b;
  background: linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
  background: -moz-linear-gradient(359deg, #6cd52b 0%, #108c10 80%);
}

.main-sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar {
  padding-left: 0px;
  padding-right: 0px;
  /* overflow-y: auto; */
  height: 80vh;
}

.sidebar::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.sidebar::-webkit-scrollbar-button {
  height: 50px;
}

.sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #1b8d6d;
  /* background-color: #426ec0; */
}

.nav-item p {
  color: var(--violet) !important;
}

.nav-item i {
  margin-right: 5px;
}

.hr-logo-link {
  background: var(--white) !important;
  height: 5rem;
}

.hr-logo-link img {
  width: 100%;
  height: 100%;
}

.dashboard-column a {
  border-radius: 0 !important;
  background: #7ebb5a !important;
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
}

.dashboard-column p {
  border-radius: 0 10% !important;
}

.sidebar-icons-color {
  color: var(--white) !important;
  font-size: 1rem;
}

.sub-maga-links {
  margin-left: 3rem;
}

.center-align {
  display: flex;
  align-items: center;
}

.admin-sidebar .sidebar {
  padding: 0px !important;
}

.admin-sidebar .brand-link.seed-link {
  border-bottom: 0px !important;
}

.navbar-white {
  background-color: rgb(83 175 32 / 20%) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #418c14 !important;
}

.content {
  padding: 15px;
}

.content-wrapper {
  background: transparent;
}

.table-responsive {
  padding: 0px !important;
  margin: 0px !important;
}

.form-control {
  padding: 3px;
}

.editor-card {
  width: 100% !important;
}

.wrapper {
  width: 99%;
}

.input-labels {
  font-size: 16px;
  font-weight: bold !important;
}

.labels {
  vertical-align: middle;
  padding-left: 5px;
}

tfoot {
  padding-left: 20px;
  width: 100%;
  float: left;
}

/* 
navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item>.nav-link.active {
    background: #2846d5;
    background: #0bbea6;
    margin-right: 0;
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0;
}
 */

@media screen and (max-width: 400px) {
  .wrapper {
    width: 98%;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 10px 7px;
  }

  .dash-card-title {
    font-size: 3rem !important;
  }
}

.nav-sidebar a {
  display: flex !important;
  align-items: flex-start !important;
  width: 100%;
  justify-content: flex-start;
}

.nav-item a {
  display: flex !important;
  align-items: center !important;
  /* align-items: flex-start !important; */
  width: 100%;
  justify-content: flex-start;
}

.brand-link.seed-link {
  background: transparent;
}
.nav-sidebar .nav-item > .nav-link {
  position: relative;
  color: white !important;
}

.upload-document-format {
  width: 1200px;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  border: 1px solid;
  border-color: #d3d3d3;
}

.italic-subscript {
  font-size: small;
  font-style: italic;
}

.upload-document-heading {
  margin: 25px 50px 0 50px;
}

.upload-document-hr {
  margin: 0;
}

.upload-bar {
  border: 1px solid;
  border-color: #d3d3d3;
  border-radius: 5px;
  margin: 15px 0 0 0;
}

.upload-icon {
  color: #4d4d4d;
  vertical-align: middle;
}

.upload-document-para {
  padding: 0;
  margin: 0;
}

.upload-progress-icons {
  display: inline-block;
}

.upload-progress-icons i {
  margin-left: 20px;
}

.edit-icon {
  font-size: 1.2rem;
  color: #4d4d4d;
}

.delete-icon {
  font-size: 1.2rem;
  color: #4d4d4d;
}

.upload-resume {
  display: inline-block;
}

.upload-document-buttons {
  margin: 5% 2.5% 5% 2.5%;
}

.back-button {
  float: left;
}

.save-next-button {
  float: right;
}

.upload-bar {
  border: 1px solid;
  border-color: #d3d3d3;
  display: flex;
  border-radius: 5px;
  margin: 15px 0 0 0;
  flex-flow: wrap;
  align-items: center;
  padding: 15px;
}

.upload-resume {
  display: inline-block;
  width: calc(100% - 80px);
}

.upload-resume p {
  padding-left: 15px;
  vertical-align: middle;
  width: 86%;
}

.header-color {
  background-color: #1b8d6d;
}

nav.header-color {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-brand {
  margin-left: 15px;
}

.header-icon {
  color: white;
  margin-left: 5px;
  margin-top: 7px;
}

.breadcrumb {
  padding: 0;
  background: #fff;
  overflow: hidden;
  font-size: 12px;
  margin-bottom: -15px;
  margin-top: 5px;
  display: block;
}

.breadcrumb li {
  float: left;
}

.breadcrumb li a {
  color: white;
  text-decoration: none;
  padding: 10px 0 10px 65px;
  background: #1b8d6d;
  background: hsla(163, 68%, 40%, 0.647);
  position: relative;
  display: block;
  float: left;
}

.breadcrumb li a::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid #1b8d6d;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb li a::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumb li:first-child a {
  padding-left: 10px;
}

.breadcrumb li:nth-child(2) a {
  background: hsl(142deg 43% 55%);
}

.breadcrumb li:nth-child(2) a:after {
  border-left-color: hsl(142deg 43% 55%);
}

.breadcrumb li:last-child a {
  background: rgb(212, 200, 184) !important;
  color: black;
  pointer-events: none;
  cursor: default;
}

.breadcrumb li:last-child a::after {
  border-left-color: rgb(212, 200, 184);
}

.breadcrumb li a:hover {
  background: hsla(34, 85%, 25%, 1);
}

.breadcrumb li a:hover:after {
  border-left-color: hsla(34, 85%, 25%, 1) !important;
}

@media screen and (max-width: 767px) {
  .upload-document-format {
    width: 100% !important;
  }

  nav.header-color {
    position: relative;
  }
}

@media screen and (max-width: 400px) {
  .upload-document-heading {
    margin: 30px 15px !important;
  }

  .page-format {
    margin-top: 10px !important;
  }

  .row-format {
    padding: 0px !important;
  }

  .upload-document-format .col {
    margin-bottom: 15px;
    flex: 100% 1;
  }
}

nav.navbar.header-color img {
  width: auto;
  background: #fff;
  height: 30px;
  padding: 1px;
}

.navbar__right-elements {
  display: flex;
  width: 8rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.avatar {
  background-color: transparent;
  cursor: pointer;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-content: center;
  /* align-items: center; */
  grid-gap: 3px;
  gap: 3px;
  padding: 1rem 1rem 0rem 1rem;
  /* background-color: aquamarine; */
}

.details__title {
  font-size: small;
  font-weight: bolder;
  /* color: white; */
}

.details__id {
  font-size: small;
  color: grey;
}


.footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 50px;
  background-color: white;
  text-align: center;
  padding: 0 15px;
  z-index: 10;
}

.footer-content {
  margin-top: 15px;
}

.footer-left-para {
  text-align: left;
  color: #4d4d4d;
}

.footer-right-para {
  text-align: right;
  color: #4d4d4d;
}

.anchor-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  background-color: black;
  display: inline-block;
  margin-left: 5%;
  margin-right: 5%;
}

.footer-anchor {
  text-decoration: none;
  color: #1b8d6d;
  font-size: small;
}
.TopBottomSpace{
  margin-bottom: 100px;
  margin-top: 93px;
}
@media only screen and (max-width: 1000px) {
  .footer {
    height: auto;
  }
  .footer-left-para {
    text-align: center;
  }
  .footer-right-para {
    text-align: center;
  }
}
@media only screen and(max-width: 767px) {
  .footer{
    position: relative;
  }
  .TopBottomSpace{
    margin-bottom: 20px;
    margin-top:20px;
  }
}
.card + button {
  margin: 3rem auto 0;
  display: flex;
}

textarea.input-field-form2.input-labels{
  line-height: 22px !important;
}
.backImg {
  /* position: relative; */
  background-image: url(/static/media/loginBackground.96e5f016.png);
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.createContainer {
  /* backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all 0.5s ease-out 0s;
  width: 30%;
  height: auto;
  border: 1px solid #efefec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  background: #ffffff;
  position: fixed;
  top: 24%;
  left: 15%; */
  width: 25%;
  max-width: 30rem;
  min-width: 24rem;
  margin: 0 0 0 8rem;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.input-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-around;
}

.input-container input {
  height: 4rem;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: x-large;
  font-weight: bold;
}

.otp-group {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  /* width: 100%; */
  /* max-width: 360px; */
  /* column-gap: 10px; */
}

/* .submitBtnn {
  margin-top: -3rem;
} */

/* .closeBtnn {
  margin-left: 5.2rem;
  margin-top: -3rem;
} */
.verify-otp {
  background-color: #3ac600;
  border: none;
  font-weight: 500;

  /* border-radius: 12px; */
  /* width: 6.5rem;
  height: 2.5rem; */
  /* margin-left: 1.6rem; */
}

.icon {
  position: relative;
  left: -18rem;
}
.eyeIcon {
  padding: 10px;
  background: rgb(56, 65, 162);
  color: wheat;
  min-width: 50px;
  text-align: center;
}

/* .inputBox {
  margin-top: 5rem;
}
.inputText {
  width: 15.5rem;
} */

.pwdBox {
  /* margin-top: 1rem;
  margin-left: 2rem;
  padding-top: 0.5rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: stretch;
}

/* class for password input textfields */
/* .txtBox { 
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-left: 2rem;
} */

/* .icon2 {
  position: relative;
  top: -1.5rem;
  left: 0.5rem;
} */

.otp-input {
  width: 2rem;
  height: 2.3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.verify-button {
  /* margin-left: 12rem; */
  background: #1b8d6d;
}

.pass-form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.pass-form__button-row {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  grid-gap: 1rem;
  gap: 1rem;
}

/* .pass-box {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  margin-left: 1.6rem;
} */

/* .reset {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 30px;
  color: green;
  padding-top: 18px;
} */
.submitBtn {
  /* margin-bottom: 0rem;
  margin-top: 0.5rem;
  margin-left: 6rem;
  width: 29%;
  border-radius: 0.3rem;
  border: none;
  height: 2rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center; */
  background-color: #3ac600;
}

.closeBtn {
  /* margin-bottom: 0rem;
  margin-top: 0.5rem;
  margin-left: 6rem; */
  /* width: 29%;
  border-radius: 0.3rem;
  border: none;
  height: 2rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center; */
  /* color: #303030; */
  /* border-color: #3ac600; */
  /* border-width: 4px; */
  /* border-radius: 8px; */
  /* font-weight: 500; */
  background: #1b8d6d;
}

.close-button {
  /* margin-top: 2rem;
  margin-left: 20rem; */
}

/* @media (width > 1440px) {
  .createContainer {
    width: 30%;
    margin-left: 8rem;
    padding: 4rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
} */

@media (min-width: 476px) and (max-width: 899px) {
  .backImg {
    justify-content: center;
  }

  .createContainer {
    width: 50%;
    max-width: auto;
    min-width: auto;
    margin-left: 0px;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
  }
}

@media only screen and (max-width: 475px) {
  .backImg {
    justify-content: center;
  }

  .createContainer {
    width: 90%;
    max-width: auto;
    min-width: auto;
    margin-left: 0px;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    /* backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    width: 80%;
    height: 24rem;
    background: #ffffff;
    border: 1px solid #efefec;
    box-sizing: border-box;
    border-radius: 0.8rem;
    position: relative;
    top: 24.5%;
    align-items: center;
    flex-wrap: wrap;
    display: block;
    left: 10.5%; */
  }
}

