.Company_Gallery {
    width: auto;
  height: auto;
  margin: 0px 0px 20px 0px;
    padding:20px;
    border-radius: 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--white);
}


.Note{
    font-family: Jost;
    font-size: 14px;
   line-height: 1.43;
  color: var(--black);
}

.required{
    height: 22px;
    margin-top: 21px;
 
    color: var(--Black-2);
}
.required p::after{
    content: "*";
    color: var(--red);
    font-weight: bold;
}

.select2{
    font-size: 13px;
    width: 15rem;
    height: 35px;
    margin: -10px 44px 26px 3px;
    padding: 6px 12.5px 7px 15px;
    border-radius: 18px;
    background-color: var(--white);
}

.selected{
  
  margin: 0 69.5px 0 0;
  font-family: Jost;
  font-size: 13px;
  color: var(--black);
}



.grp{
    display: inline;
    
    
}
.checks_image{
    display: inline;
    /* margin-left: 7%; */
}

.checks_video{
    display: inline;
   
}
.check{
    display: inline;
    margin-left: 15px;
    border-radius: 50px;
    background-color: var(--white-2);

}

.labels{
    margin-left: 30px;
   color: var(--Black-2);
}
.upload{
 
  color: var(--Black-2);

}

.upload p::after{
    content: "*";
    color: var(--red);
    font-weight: bold;
}

.upload-image p::after{
    content: "*";
    color: var(--red);
    font-weight: bold;
}
.upload-image{
  
  margin: 21px 66px 3px 3px;

  color: var(--Black-2);
}

.custom-file{   
    
    
    height: 35px;
    margin: 3px 0px 0 0px;
    padding: 5px;
    border-radius: 18px;
    background-color: var(--white-2);
}

.custom-label-input{

    cursor: pointer;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: Jost;
    font-size: 13px;
    color: var(--choose_file_white);
}

.submmit{
    display: inline;
}

.btn4{
   
    width: 95px;
    height: 36px;
    border-radius: 18px;
    background-color: var(--Navy_blue);
}




