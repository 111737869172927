.Dashboard{
    position: relative;
}


.Image-27 {
    /* position: absolute  ;
    width: 1366px;
    z-index: 1;
    height: 772px; */
    margin: 0 0 27px;
  padding: 0 0 27px;
   object-fit: contain;
  }
 
 
  .Image-345{
      /* position: absolute;
      z-index: 2;
    width: 678px;
    height: 476px;
    margin: 88px 58px 81px 630px; */
    object-fit: contain;
  }



 .Image-2222 {
    /* position: absolute;
    margin-top: 310px;
    margin-left: 280px;
    z-index: 3;
    width: 693px;
    height: 610px;
    margin: 35px 435px 0 238px;
    padding: 175px 291px 149px 42px; */
    object-fit: contain;
  }

 
.dash-card-title{
      /* position: absolute;
      z-index: 4;
      font-family: Jost;
      margin-left: 280px;
      
      margin-top:250px; */
      margin-top:4rem;
      width:100%;
  float: left;
  font-size: 64px;
  font-weight: 500;
   
    line-height: 50px;
   
  color: #74d02a;
  }

  .employee{
      /* position: absolute;
      z-index: 4;
      margin-left: 280px;
      margin-top:310px; */
      margin-top:2rem;
      width:100%;
  float: left;
    font-family: Jost;
    font-size: 33px;
  
    
    color: #8046d2;
  }

  .card-text{
    /* position: absolute;
    z-index: 4;
    margin-left: 280px;
    margin-top:360px; */
  font-family: Jost;
  width:100%;
  float: left;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #3a3a3a;
  }