.login-container {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all 0.5s ease-out 0s;
  width: 23%;
  height: 25rem;
  background: #ffffff;
  border: 1px solid #efefec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  position: absolute;
  top: 22.5%;
  left: 15%;
}

.loginPage {
  position: relative;
  background-image: url("../../../../Images/loginBackground.png");
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  height: 100vh;
}

.login {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 30px;
  color: green;
  padding-top: 18px;
}

.loginHead {
  margin-top: 62px;
  font-weight: 400;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 129px;
}

.loginBtn {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 44%;
  border-radius: 0.3rem;
  border: none;
  background-color: green;
  height: 2.3rem;
  color: white;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.imgHead {
  margin-top: -51px;
  flex-wrap: wrap;
  align-items: center;
}

.InputField {
  display: block;
  width: 85%;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 10px;
  border: 0.1px solid;
  padding-left: 1.5rem !important;
}

.forget {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #1b8d6d;
  margin-left: 24px;
  margin-bottom: -1rem;
  display: block;
  margin-top: -17px;
  cursor: pointer;
}

.label4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  color: #ffffff;
}

.label5 {
  top: calc(50% - 15px / 2 + 336px);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 140%;
  color: #ffffff;
}

.login-footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}



@media only screen and (max-width: 767px) {
  .login-container {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out 0s;
    width: 80%;
    height: 24rem;
    background: #ffffff;
    border: 1px solid #efefec;
    box-sizing: border-box;
    border-radius: 0.8rem;
    position: relative;
    top: 24.5%;
    align-items: center;
    flex-wrap: wrap;
    display: block;
    left: 10.5%;
  }
}