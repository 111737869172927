
.Append {
  margin: 20px;
  border: 0.1px solid var(--light-gray);
  height: 31px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-left: 0px;
}

.input-group > .form-control {
  width: 200px;
}

.btn{
  color: var(--white);
}
.btn6 {
  float: left;
  /* width: 181px; */
  height: 36px;
  padding: 8px 29px 6px 19.2px;
  border-radius: 18px;
  background-color: var(--Navy_blue);
}


 


















