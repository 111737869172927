.address-margin {
  margin-left: 2.5rem;
  margin-top: 2rem;
}

.Select-Company-HA {
  width: 20.5rem;
  height: 2.3rem;
  margin: 0 1.9rem 1rem 0;
  padding: 0px 19.5px 7px 0px;
  border-radius: 18px;
  background-color: var(--seconday);
}
/* #error{
  color: red;
  font-size:12px;
  display: none;
} */
.Select-CompanyHA {
  width: 20.5rem;
  height: 2.3rem;
  font-family: Jost;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border-radius: 18px;
  border: 0;
  box-shadow: none;
  background-color: var(--seconday);
}

.input-field-HA {
  width: 23rem;
  height: 2.3rem;
  margin-bottom: 2.5rem;
  border: none;
  border-radius: 18px;
  background-color: var(--seconday);
}

.input-left-margin {
  margin-left: 4rem;
}

.Company-lable {
  width: 99px;
  height: 22px;
  margin: 11px 280px 5px 3px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
}

.address-lable {
  margin: 1.1rem 14rem 5rem 0.3rem;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Jost;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}
